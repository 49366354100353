body {
  max-width: 95vw;
}

:root {
  --text-main: white;
  --text-bright: white;
  --background-body: #111213;
}

button {
  background-color: #0a402c;
  color: white;

}

button:hover {
  background-color: #137752;
  color: white;
}

input {
  background-color: #137752;
  color: white;
  width: 100%;
}

form > span {
  width: 100%;
}

select {
  background-color: #137752;
  color: white;
  width: 100%;
}
